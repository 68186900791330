export class commonInit {
  constructor() {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  }

  init() {
    this.setEvents();
  }

  setEvents() {
    let hides = document.querySelectorAll('.seo-mapper__text-hide');
    hides.forEach(hide=>{
      hide.addEventListener('click', e => this.toggleHideShow(e))
    });
  }

  toggleHideShow(e) {
    let target = this.findInPath(e, 'seo-mapper__text-hide');

    if (target) {
      target.classList.toggle("showed");
    }
  }

  findInPath(e, selector) {
    let path = e.path || e.composedPath();
    selector = selector.replace(/^\./, '');

    return path.find(el => el.classList && el.classList.contains(selector));
  }

  dispatchEvent(el, type) {
    if ('createEvent' in document) {
      // modern browsers, IE9+
      let evt = document.createEvent('HTMLEvents');
      evt.initEvent(type, false, true);
      el.dispatchEvent(evt);
    } else {
      // IE 8
      // noinspection JSUnresolvedFunction
      let evt = document.createEventObject();
      evt.eventType = type;
      // noinspection JSUnresolvedFunction
      el.fireEvent('on' + evt.eventType, evt);
    }
  }

}
